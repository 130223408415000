import {categories} from './tableModules/categories'
import {contacts} from './tableModules/contacts'
import {costCenters} from './tableModules/costCenters'
import {devices} from './tableModules/devices'
import {loginProviders} from './tableModules/loginProviders'
import {mailTemplates} from './tableModules/mailTemplates'
import {orders} from './tableModules/orders'
import {organisations} from './tableModules/organisations'
import {users} from './tableModules/users'

export const tableModules: TableModules = {
    users,
    organisations,
    testUsers: {
        ...users,
        name: 'testUsers'
    } as TableModule,
    contacts,
    loginProviders,
    categories,
    costCenters,
    devices,
    mailTemplates,
    orders
}
