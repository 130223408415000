export const orders: TableModule = {
    name: "orders",
    route: "spa.orders",
    columns: [
        {
            name: "type",
            sortable: true,
            mobile: true,
            pageLink: true,
            width: "w-[80px]"
        },
        {
            name: "name",
            sortable: true,
            mobile: true,
            pageLink: true,
            width: "w-1/2 sm:w-1/3 lg:w-1/4"
        },
        {
            name: "sum",
            sortable: true,
        },
        {
            name: "user_id",
            sortable: true
        },
        {
            name: "created_at",
            sortable: true,
        },
        {
            name: "status",
            sortBy: "status_id",
            sortable: true,
            mobile: true
        },
    ],
    filters: [
        {
            name: "type",
            column: "orders.type",
            type: "select",
            reduced: true,
            options: [
                {
                    value: "order",
                    label: "Beschaffung"
                },
                {
                    value: "device",
                    label: "Standardgeräte"
                },
                {
                    value: "special",
                    label: "Sonderbestellung"
                },
                {
                    value: "express",
                    label: "Eilbestellung"
                }
            ]
        },
        {
            name: "name",
            column: "orders.name",
            type: "string",
            reduced: true,
        },
        {
            name: "status",
            column: "orders.status_id",
            type: "select",
            options: () => $lara.get(
                useNuxtApp().$apiRoute('spa.statuses.index')
            ).then(response => {
                const statuses = {
                    open: "Gestartet",
                    updated: 'Korrigiert',
                    cost_center_approved: 'KST genehmigt',
                    data_center_approved: 'RZ genehmigt',
                    finance_approved: 'FZ genehmigt',
                    rejected: 'Abgelehnt',  
                    closed: 'Geschlossen',  
                    canceled: 'Storniert'
                }
                
                return response?.map(
                    status => ({
                        value: status.id,
                        label: statuses[status.name]
                    }))
            }),
            reduced: true,
            multiple: true
        },
    ],
    quickFilters: [
        {
            name: "open",
            column: "orders.status_id",
            operator: "=",
            value: "1",
        }
    ],
    tools: [
        {
            name: "orders.create",
            isCreateAction: true,
            ability: ['create', 'orders']
        },
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
        {
            name: "orders.delete",
            color: "rose",
            icon: "heroicons:trash"
        }
    ]
}